/*****************************************************************************
 * UmanIT - Word backgrounds on scroll
 *****************************************************************************/

let backgrounds = document.querySelectorAll("[data-background='word-background']");

checkPosition();
document.addEventListener("scroll", function() {
  setTimeout(function () {

  }, 100);
  checkPosition();
});

window.addEventListener("resize", function () {
  setTimeout(function () {
    document.addEventListener("scroll", function () {
      checkPosition();
    });
  }, 100);
});

/**
 * Fonction qui détecte quel type de background doit s'afficher
 *
 * return {void}
 */
function checkPosition() {
  backgrounds.forEach(function(background) {
    const backgroundOffset = background.getBoundingClientRect();
    const bottom = backgroundOffset.bottom;
    const top = backgroundOffset.top;

    if(background.classList.contains("word-bg--bottom")) {
      checkVisibility(bottom, background);
    } else {
      checkVisibility(top, background);
    }
  });
}

/**
 * Fonction qui détecte si le conteneur du background est visible à l'écran
 *
 * return {void}
 */
function checkVisibility(coord, background) {
  if(coord > 0 && coord < window.innerHeight) {
    addABackgroundAnimation(background);
  }
}

/**
 * Fonction qui ajoute les classes pour l'animation
 *
 * return {void}
 */
function addABackgroundAnimation(background) {
  if(!background.classList.contains("animate")) {
    background.classList.add("animate");
  }
}


